<script setup lang="ts">
import type { ProductSearchResultFragment, SearchResultSortParameter } from '#graphql-operations'
import { createStoreStateHash } from '~/composables/search'
import { useProductSearchState } from '~/composables/productSearch'

const props = withDefaults(defineProps<{
  page?: number
  term?: string
  take?: number
  facetValueIds?: string[]
  collectionId?: string
  sort?: SearchResultSortParameter
}>(), { page: 1, take: 36 })

const emit = defineEmits<{
  (event: 'update:page', page: number): void
}>()

const { ecommerce } = useAnalytics()
const { page, term, take, sort, facetValueIds, collectionId } = toRefs(props)
const { t } = useI18n()

const { pages, products } = useProductSearchState()
const allItemsLoaded = ref(false)
const loadingMore = ref(false)

const input = computed(() => ({ term: term?.value ?? '', facetValueIds: facetValueIds?.value ?? [], collectionId: collectionId?.value ?? '', page: +page.value, take: take.value, sort: sort?.value ?? {} }))

const searchHash = computed(() => createStoreStateHash(input.value))

const { data, pending, error } = useAsyncData('productSearch', async () => {
  const currentSearchPages = pages.value[searchHash.value]
  const currentPageItems = currentSearchPages?.[input.value.page]

  if (currentPageItems?.length) {
    const items = currentPageItems.map(id => products.value[id])
    const totalItems = currentSearchPages.totalItems

    if (currentPageItems.length < input.value.take || currentPageItems.length === totalItems)
      allItemsLoaded.value = true

    return {
      productSearch: { items, totalItems },
      searchFacetValues: { items: [] },
    }
  }

  const { data: { productSearch, searchFacetValues } } = await useGraphqlQuery('productSearch', {
    search: {
      term: input.value.term,
      collectionId: input.value.collectionId,
      facetValueFilters: input.value.facetValueIds.length ? [{ or: input.value.facetValueIds }] : [],
      skip: getPaginationOffset(input.value.page, +input.value.take),
      take: input.value.take,
      sort: input.value.sort,
    },
    searchFacetValues: {
      term: input.value.term,
      collectionId: input.value.collectionId,
      facetValueFilters: [], // keep empty to get all for current search, for filtering
    },
  })

  const itemIds = productSearch.items.map(item => item.id)

  if (!itemIds.length || itemIds.length < input.value.take)
    allItemsLoaded.value = true

  if (currentSearchPages) {
    currentSearchPages[input.value.page] = itemIds
    currentSearchPages.totalItems = productSearch.totalItems
  } else {
    pages.value[searchHash.value] = {
      [input.value.page]: itemIds,
      totalItems: productSearch.totalItems,
    }
  }

  productSearch.items.filter(Boolean).forEach((item) => {
    if (products.value[item.id])
      Object.assign(products.value[item.id], item)
    else
      products.value[item.id] = item
  })

  return { productSearch, searchFacetValues }
}, {
  server: false,
  lazy: true,
  deep: false,
  watch: [input],
})

let previousScrollPosition = 0
const { y: scrollY } = useWindowScroll()

const items = computed(() => {
  const currentSearchPages = pages.value?.[searchHash.value]
  if (!currentSearchPages)
    return []

  // show only items from the current page and previous pages if any
  return Object.entries(currentSearchPages)
    .filter(([key, value]) => !isNaN(+key) && +key <= input.value.page)
    .flatMap(([key, value]) => value)
    .map(id => products.value[id])
})

watch(pending, (to) => {
  loadingMore.value = to === true && items.value?.length >= input.value.take
}, { immediate: true })

function loadMore() {
  if (!allItemsLoaded.value && !loadingMore.value && !pending.value) {
    loadingMore.value = true
    previousScrollPosition = scrollY.value
    emit('update:page', page.value + 1)
  }
}

watchEffect(async () => {
  if (data.value && loadingMore.value) {
    await nextTick()
    scrollY.value = previousScrollPosition
  }
})

const { addItemToHistory } = useProductHistory()

function handleClick(item: ProductSearchResultFragment) {
  addItemToHistory(item)
  ecommerce.selectItem(item)
}
</script>

<template>
  <div>
    <!-- Loading Indicator Slot -->
    <slot v-if="pending && !loadingMore" name="loading">
      <!-- Default loading state -->
      <div class="mb6">
        <div class="grid grid-cols-2 mt6 gap-x-2 gap-y-3 sm:grid-cols-minmax-14rem lg:grid-cols-5 md:grid-cols-4 sm:gap-x-4 sm:gap-y-6">
          <ProductCard v-for="(n, index) in take" :key="index" />
        </div>
      </div>
    </slot>

    <!-- No Items Found Slot -->
    <slot v-else-if="items && items.length === 0" name="no-items">
      <div>
        Nie znaleziono żadnych elementów.
      </div>
    </slot>

    <!-- Items Grid Slot -->
    <div v-else class="mb6">
      <slot :items="items" :handle-click="handleClick" name="items">
        <div class="grid grid-cols-2 mt6 gap-x-2 gap-y-4 sm:grid-cols-minmax-12rem">
          <ProductCard
            v-for="item in items"
            :key="item.id"
            v-memo="[item]"
            :item="item"
            @click="handleClick(item)"
          />

          <!-- Skeletons for new items being loaded -->
          <template v-if="loadingMore">
            <ProductCardSkeleton v-for="n in input.take" :key="`skeleton-${n}`" />
          </template>
        </div>
      </slot>
    </div>

    <!-- Optional: Load More Button (can be removed if solely using infinite scroll) -->
    <div class="text-center">
      <NButton
        v-if="!allItemsLoaded && !pending && !loadingMore"
        id="load-more"
        class="rounded-md leading-7 shadow-sm ring-1 ring-black/7"
        @click="loadMore"
      >
        {{ t('general.load_more.label') }}
      </NButton>
    </div>
  </div>
</template>
