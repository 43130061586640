import type { SearchResultFragment } from '#graphql-operations'

// This is global state which is kept on the through the entire app lifecycle e.g. client and server side until page reload.
export function useProductSearchState() {
  const pages = useState<{ [searchHash: string]: { [page: number]: string[]; totalItems: number } }>(() => ({}))
  const products = useState<{ [id: string]: SearchResultFragment }>(() => ({}))

  return { pages, products }
}

export function useProductSearch() {
}
