import { useStorage } from '@vueuse/core'

import type { SearchResultFragment } from '#graphql-operations'

const ITEM_LIFETIME = 86400000 // 1 day
const CHECK_INTERVAL = 3600000 // 1 hour

export function useProductHistory() {
  const history = useStorage<(SearchResultFragment & { ts: number })[]>('product-history', [])
  const checkInterval = ref<NodeJS.Timeout | undefined>()

  const addItemToHistory = (item: SearchResultFragment & { ts: number }) => {
    const index = history.value.findIndex(i => i.id === item.id)
    if (!~index) {
      history.value.unshift(item)
      history.value = history.value.slice(0, 12)
    } else {
      history.value.splice(index, 1)
      history.value.unshift(item)
    }
  }

  const clearProductHistory = () => {
    history.value = []
  }

  const checkProductFreshness = () => {
    const now = Date.now()
    history.value = history.value.filter(item => item?.ts ? now - item.ts < ITEM_LIFETIME : true)
  }

  onMounted(() => {
    checkInterval.value = setInterval(checkProductFreshness, CHECK_INTERVAL)
  })

  onUnmounted(() => checkInterval.value && clearInterval(checkInterval.value))

  return {
    history,
    addItemToHistory,
    clearProductHistory,
  }
}
